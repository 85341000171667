<template>
	<v-container v-if="pet" fluid>
		<v-btn
			color="grey darken-2"
			class="hidden-sm-and-down mt-5"
			text
			dark
			@click="goBack"
		>
			<v-icon>
				mdi-arrow-left-thick
			</v-icon>
			{{ $t('back') }}
		</v-btn>
		<v-icon
			color="grey darken-2"
			class="hidden-md-and-up mt-5"
			text
			dark
			@click="goBack"
		>
			mdi-arrow-left-thick
		</v-icon>

		<h2 class="text-center mb-5">
			{{ pet.name }} {{ $t('petDetailsHeading') }}
		</h2>

		<!-- Show Mode -->
		<v-row class="mb-10">
			<v-col
				:align="$vuetify.breakpoint.smAndDown ? 'center' : 'end'"
				cols="12"
				sm="6"
			>
				<!-- Preview Image -->
				<v-avatar v-if="imageUrl" class="profile ml-6" size="250">
					<v-img :src="imageUrl"></v-img>
				</v-avatar>

				<!-- Avatar -->
				<v-avatar class="profile" size="250">
					<v-img :src="pet.image ? pet.image : defaultAvatar"></v-img>
				</v-avatar>
			</v-col>
			<v-col
				v-if="!editMode"
				align-self="center"
				:align="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
				cols="12"
				sm="6"
			>
				<div v-if="pet.type" class="mb-1">{{ pet.type }}</div>
				<div v-if="pet.sex" class="mb-1">{{ pet.sex }}</div>
				<div v-if="pet.breed" class="mb-1">{{ pet.breed }}</div>
				<div v-if="pet.birthdate && ageYears > 0" class="mb-1">
					{{ ageYears }} {{ $t('yearsOld') }}
				</div>
				<div v-else-if="pet.birthdate && ageYears === 0" class="mb-1">
					{{ ageMonths }} {{ $t('monthsOld') }}
				</div>
				<div v-if="pet.birthdate" class="mb-1">
					{{ $t('born') }} {{ formatDate(pet.birthdate) }}
				</div>
				<div v-if="pet.weight" class="mb-1">{{ pet.weight }}</div>

				<v-btn class="mr-2" color="secondary" small @click="editMode = true"
					><v-icon small> mdi-pencil </v-icon>{{ $t('edit') }}</v-btn
				>
				<v-btn color="red darken-2" small dark @click="dialogDelete = true"
					><v-icon small> mdi-delete </v-icon>{{ $t('delete') }}</v-btn
				>
			</v-col>
		</v-row>

		<!-- Loader -->
		<v-row>
			<v-col align="center">
				<v-progress-circular
					v-if="isLoading"
					:width="3"
					color="green"
					indeterminate
					class="text-center"
				></v-progress-circular>
			</v-col>
		</v-row>

		<!-- Edit Mode -->
		<EditPetForm
			:editMode="editMode"
			@save="save"
			@close="close"
			@preview-image="previewImage"
		/>

		<v-expansion-panels focusable>
			<!-- Nutrition -->
			<v-expansion-panel class="mb-3">
				<v-expansion-panel-header>{{
					$t('nutrition')
				}}</v-expansion-panel-header>
				<v-expansion-panel-content>
					<Nutritions />
				</v-expansion-panel-content>
			</v-expansion-panel>

			<!-- Dewormings -->
			<v-expansion-panel class="mb-3">
				<v-expansion-panel-header>{{
					$t('dewormings')
				}}</v-expansion-panel-header>
				<v-expansion-panel-content>
					<Dewormings />
				</v-expansion-panel-content>
			</v-expansion-panel>

			<!-- Vaccines -->
			<v-expansion-panel class="mb-3">
				<v-expansion-panel-header>{{
					$t('vaccines')
				}}</v-expansion-panel-header>
				<v-expansion-panel-content>
					<Vaccines />
				</v-expansion-panel-content>
			</v-expansion-panel>

			<!-- Treatments -->
			<v-expansion-panel class="mb-3">
				<v-expansion-panel-header>{{
					$t('treatments')
				}}</v-expansion-panel-header>
				<v-expansion-panel-content>
					<Treatments />
				</v-expansion-panel-content>
			</v-expansion-panel>

			<!-- Alergies -->
			<v-expansion-panel class="mb-3">
				<v-expansion-panel-header>{{
					$t('alergies')
				}}</v-expansion-panel-header>
				<v-expansion-panel-content>
					<Alergies />
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

		<DeleteModal
			:dialogDelete="dialogDelete"
			:title="$t('confirmDelete')"
			@cancel="closeDelete"
			@ok="deleteConfirm"
		/>
	</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default {
	name: 'Pet',
	components: {
		Dewormings: () => import('@/components/pets/Dewormings'),
		Nutritions: () => import('@/components/pets/Nutritions'),
		Vaccines: () => import('@/components/pets/Vaccines'),
		Treatments: () => import('@/components/pets/Treatments'),
		Alergies: () => import('@/components/pets/Alergies'),
		EditPetForm: () => import('@/components/pets/EditPetForm'),
		DeleteModal: () => import('@/components/DeleteModal'),
	},

	// props: {
	// 	id: {
	// 		required: true,
	// 	},
	// },
	data() {
		return {
			isLoading: false,
			editMode: false,
			imageUrl: null,
			dialogDelete: false,
			defaultAvatar: require('@/assets/default-avatar.jpg'),
		};
	},
	computed: {
		...mapState('pets', ['pet']),
		ageYears() {
			return moment().diff(moment(this.pet.birthdate, 'YYYYMMDD'), 'years');
		},
		ageMonths() {
			return moment().diff(moment(this.pet.birthdate, 'YYYYMMDD'), 'months');
		},
	},
	methods: {
		...mapActions('pets', ['getPet', 'editPet', 'deletePet']),
		formatDate: date => (date = moment(date).format('DD.MM.YYYY')),
		previewImage(image) {
			this.imageUrl = URL.createObjectURL(image);
		},
		goBack() {
			this.$router.go(-1);
		},
		save() {
			this.editMode = false;
			this.imageUrl = null;
		},
		closeDelete() {
			this.dialogDelete = false;
		},
		deleteConfirm() {
			this.delPet();
			this.dialogDelete = false;
		},
		close() {
			this.editMode = false;
			this.imageUrl = null;
		},
		async delPet() {
			this.isLoading = true;

			try {
				await this.deletePet(this.pet._id);

				this.isLoading = false;
				this.$router.push('/pets');
			} catch (error) {
				console.error(error);
			}
		},
	},
	async mounted() {
		this.isLoading = true;

		try {
			await this.getPet(this.$route.params.id);
		} catch (error) {
			console.error(error);
		} finally {
			this.isLoading = false;
		}
	},
};
</script>

<style lang="scss" scoped></style>
